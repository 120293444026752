import { createQueryKeys } from '@lukemorales/query-key-factory';

import { getData } from '../utils/functional/fp';

import {
  fetchOperationalStatuses,
  fetchSubmissionsMetrics,
  fetchSubmissionsStatuses,
  getP250SecurityQuestionsAdapterForSubmissionId,
  getSubmissionById,
  getSubmissionByMsgId,
  getSubmissionEligibleProducts,
  getSubmissionsUsers,
  searchSubmissions,
} from '../api/submissions/submissions.api';

export const submissions = createQueryKeys('submissions', {
  details: (submissionId: string) => ({
    queryKey: [{ submissionId }],
    queryFn: () => getSubmissionById(submissionId).then(getData),
  }),

  detailsByMsgId: (msgId: string) => ({
    queryKey: [{ msgId }],
    queryFn: () => getSubmissionByMsgId(msgId).then(getData),
  }),

  list: (query: Record<string, unknown>) => ({
    queryKey: [{ query }],
    queryFn: () => searchSubmissions({ params: query }).then(getData),
  }),

  metrics: (query: Record<string, unknown>) => ({
    queryKey: [{ query }],
    queryFn: () => fetchSubmissionsMetrics({ params: query }).then(getData),
  }),

  // TODO: Query needs to be adjusted for workspace only submissions
  workspace: (query: Record<string, unknown>) => ({
    queryKey: [{ query }],
    queryFn: () => searchSubmissions({ params: query }).then(getData),
  }),

  statuses: () => ({
    queryKey: ['statuses'],
    queryFn: () => fetchSubmissionsStatuses().then(getData),
  }),

  operationalStatuses: () => ({
    queryKey: ['operational-statuses'],
    queryFn: () => fetchOperationalStatuses().then(getData),
  }),

  submissionsUsers: () => ({
    queryKey: ['submissions-users'],
    queryFn: () => getSubmissionsUsers().then(getData),
  }),

  eligibleProducts: (submissionId: string) => ({
    queryKey: [{ submissionId }],
    queryFn: () => getSubmissionEligibleProducts(submissionId).then(getData),
  }),

  p250SecurityQuestions: (submissionId: string) => ({
    queryKey: [{ submissionId }],
    queryFn: () =>
      getP250SecurityQuestionsAdapterForSubmissionId(submissionId).then(
        getData
      ),
  }),
});
